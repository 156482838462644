import { PersonalInfoService } from "./../../profile/personal-info/personalInfo.service";
import { Component, OnInit } from "@angular/core";
import { LoginService } from "../../login/login.service";
import { environment } from "src/environments/environment";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
import { Router } from "@angular/router";
import { CommonService } from "src/app/common/common.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  model: any = {};
  profile_pic;
  assetUrl = environment.assetUrl;
  show: boolean = false;
  is_next_apt: any;
  is_profileCompleted = true;
  constructor(
    private loginservice: LoginService,
    private personalInfoService: PersonalInfoService,
    public snackBar: MatSnackBar,
    private route: Router,
    private api: CommonService
  ) {
    this.model.is_bank_details_updated = true;
  }
  ngOnInit(): void {
    // this.navigateSelectedUser();
    // this.model.profile_pic = "/assets/images/avatar-1.png";
    this.getPersonalInfo();
  }
  toggleCollapse() {
    this.show = !this.show;
  }
  getPersonalInfo() {
    this.personalInfoService.getProfile().subscribe((response: any) => {
      if (response.status == true) {
        this.model = response.data;
        this.updateProfileStatus(
          this.model.is_profilecompleted,
          this.model.is_educationcompleted,
          this.model.is_specializationcompleted
        );
        this.is_next_apt = this.model.next_appointment;
        this.model.name = this.model.first_name + " " + this.model.last_name;
      }
    });
  }
  gettimezone(timezone) {
    switch (timezone) {
      case "GMT+5:30":
        return "IST";
      case "GMT+8:00":
        return "SGT";
      case "GMT+1:00":
        return "GMT";
      case "GMT+4:00":
        return "UAE";
      default:
        return timezone;
    }
  }
  setProfile(profile_pic, doctor_name) {
    this.model.profile_pic = profile_pic;
    this.model.name = doctor_name;
  }
  setCategory(speciality) {
    this.model.category = speciality;
  }
  logout() {
    this.loginservice.logout();
  }
  updatePaymentStatus(val) {
    this.model.is_bank_details_updated = val;
  }
  updateProfileStatus(is_profile, is_education, is_specialization) {
    if (!is_profile || !is_education || !is_specialization) {
      this.is_profileCompleted = true;
    } else {
      this.is_profileCompleted = false;
    }
  }
  navigateSelectedUser() {
    this.api.getProfile().subscribe(
      (response: any) => {
        let userInfo = response.data;
        if (
          userInfo.is_profilecompleted &&
          userInfo.is_educationcompleted &&
          userInfo.is_specializationcompleted
        ) {
          // this.route.navigate(["dashboard"]);
        } else if (!userInfo.is_profilecompleted) {
          this.openSnackBar(
            "Before going to profile complete your personal information",
            ""
          );
          this.route.navigateByUrl("/registration/personal-info");
        } else if (!userInfo.is_educationcompleted) {
          this.openSnackBar(
            "Before going to profile complete your details",
            ""
          );
          this.route.navigate(["/registration/education"]);
        } else if (!userInfo.is_specializationcompleted) {
          this.openSnackBar(
            "Before going to profile complete your details",
            ""
          );
          this.route.navigate(["/registration/specialization"]);
        }
      },
      (err) => {}
    );
  }
  openSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = "center";
    config.verticalPosition = "top";
    config.panelClass = ["green-snackbar"];
    this.snackBar.open(message, action, config);
  }

  async reloadPage(){
    await this.route.navigate(['/appointment-details',{id : this.is_next_apt.bookingunique_id}]);
    window.location.reload()
  }
}
