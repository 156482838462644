import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Second Consultant";
  doctorSignupUrl: any = environment.doctorUrl + "/#/registration/signup";
  constructor(private router: Router) { }
  
  deepLink() {
    let navigate = navigator.userAgent.toLowerCase();
    let isAndroid = navigate.indexOf('android') > -1;
    let isIphone = navigate.indexOf("iphone") > -1;
    if (isIphone == true && window.location.href != this.doctorSignupUrl) {
      let app = {
        launchApp() {
          window.location.replace('secondconsultfordoctors://');
          // setTimeout(() => {
          //   this.openAppStore();
          // }, 500);
        },
        // openAppStore() {
        //   window.location.href = 'https://itunes.apple.com/us/app/secondconsultfordoctors/1583535773';
        // }
      }
      app.launchApp();
    } else if (isAndroid == true && window.location.href != this.doctorSignupUrl) {
      let app = {
        launchApp() {
          window.location.replace('secondconsultfordoctors://');
          // setTimeout(() => {
          //   this.openPlayStore();
          // }, 500);
        },
        // openPlayStore() {
        //   window.location.href = 'https://play.google.com/store/apps/details?id=com.sc_doctor_app';
        // }
      }
      app.launchApp();
    } 
  }

  ngOnInit() {    
    // this.deepLink();
  }
}
