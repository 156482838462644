import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor(private http: HttpClient) {}

  getAllCountry() {
    let apiURL = API_URL + "get-allcountry";
    return this.http.get(apiURL);
  }

  getAllState(countryId) {
    let apiURL = API_URL + "get-allstate/" + countryId;
    return this.http.get(apiURL);
  }

  getAllCity(stateId) {
    let apiURL = API_URL + "get-allcity/" + stateId;
    return this.http.get(apiURL);
  }

  getAllLanguage() {
    let apiURL = API_URL + "get-all-language";
    return this.http.get(apiURL);
  }

  getAllDoctorSpeciality() {
    let apiURL = API_URL + "get-all-speciality";
    return this.http.get(apiURL);
  }
  getProfile() {
    let apiURL = API_URL + "get-doctor-profile";
    return this.http.get(apiURL);
  }
}
