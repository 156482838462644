<ul class="navbar-nav">
  <li class="nav-item">
    <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">
      <i class="fa fa-tachometer mr-4"></i> Dashboard
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/appointments" routerLinkActive="active">
      <i class="fe fe-calendar"></i>Appointments
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/second-opinion" routerLinkActive="active">
      <i
        class="fa fa-handshake-o mr-4"
        style="margin-right: 1rem !important"
      ></i
      >Second Opinion
    </a>
  </li>
  <!-- <li class="nav-item">
    <a class="nav-link" routerLink="/availability" routerLinkActive="active">
      <i class="fe fe-calendar"></i>Doctor Availability
    </a>
  </li> -->
  <li class="nav-item">
    <a class="nav-link" routerLink="/schedule" routerLinkActive="active">
      <i class="fe fe-calendar"></i>Appointment Schedule
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/doctor-schedule" routerLinkActive="active">
      <i class="fa fa-calendar mr-4"></i>
      Doctor Schedule
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/my-patients" routerLinkActive="active">
      <i class="fe fe-user"></i>My Patients
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/revenue" routerLinkActive="active">
      <i class="fa fa-inr mr-4"></i>Revenue
    </a>
  </li>

  <li class="nav-item">
    <a class="nav-link" routerLink="/settings" routerLinkActive="active">
      <i class="fa fa-cog mr-4"></i>Settings
    </a>
  </li>

  <li class="nav-item">
    <a
      class="nav-link"
      routerLink="/transactions"
      aria-controls="tab6"
      role="tab"
      data-toggle="tab"
      routerLinkActive="activelink"
      routerLinkActiveOptions="{exact:true}"
    >
      <i class="fa fa-heartbeat mr-4"></i> Transactions
    </a>
  </li>
  <!-- <li class="nav-item">
    <a
      class="nav-link"
      routerLink="/payouts"
      aria-controls="tab5"
      role="tab"
      data-toggle="tab"
      routerLinkActive="active"
      routerLinkActiveOptions="{exact:true}"
    >
      <i class="fa fa-user-md"></i>
      Payouts
    </a>
  </li> -->
  <li class="nav-item">
    <a class="nav-link" routerLink="/help-videos" routerLinkActive="active">
      <i class="fa fa-video-camera mr-4"></i>Help Videos
    </a>
  </li>
  <li class="nav-item">
    <a
      class="nav-link cursor-pointer"
      (click)="logout()"
      routerLinkActive="active"
    >
      <i class="fa fa-sign-out mr-4"></i>Logout
    </a>
  </li>
</ul>
