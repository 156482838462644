<footer>
  <div class="footer pt-lg-4 footer-dark fbg">
    <div class="container pt-4">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mb-5 mb-lg-0">
          <a href="{{ baseUrl }}">
            <img
              id="footer-logo"
              alt="Second Consult"
              src="../../../assets/images/footer-logo.png"
            />
          </a>
          <!-- <div class="member mt-4">
            <h5>Already a member?</h5>
            <div>
              <a class="anm cursor-pointer" (click)="signIn()">Sign in</a
              ><span style="padding: 0px 10px">or</span>
              <a class="anm cursor-pointer" (click)="signUp()">Sign up</a>
            </div>
          </div> -->
          <div class="member mt-4">
            <h5>Doctor Signup</h5>
            <div>
              <a routerLink="registration/signup" class="anm">Apply Here</a>
            </div>

            <!-- <div class="apps fhead mt-4 d-view">
              <a href="#"
                ><img
                  id="footer-logo"
                  src="../../../assets/images/appstore.png"
                  style="margin-right: 5px"
              /></a>
              <a href="#"
                ><img
                  id="footer-logo"
                  src="../../../assets/images/googleplay.png"
              /></a>
            </div> -->
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-2 mb-5 mb-lg-0">
          <h5 class="heading mb-3">Services</h5>
          <ul class="list-unstyled">
            <li>
              <a href="{{ baseUrl }}/tele-consultation">Tele Consultation </a>
            </li>
            <li>
              <a href="{{ baseUrl }}/second-opinion">Second Opinion </a>
            </li>
            <li>
              <a href="{{ baseUrl }}/specialities">Specialities </a>
            </li>
            <li>
              <a href="{{ baseUrl }}/health-wallet">Health Wallet </a>
            </li>
          </ul>
        </div>
        <!-- <div class="col-lg-2 col-6 col-sm-4 col-12 col-md-6 col-lg-3 mb-5 mb-lg-0">
          <h5 class="heading mb-3">Specialties</h5>
          <ul class="list-unstyled" *ngIf="specialtyList?.length > 0">
            <li *ngFor="let category of specialtyList">
              <a
                class="cursor-pointer"
                (click)="selectSepciality(category.id_speciality)"
                >{{ category.category }}</a
              >
            </li>
          </ul>
        </div> -->
        <div class="col-12 col-md-6 col-lg-2 mb-5 mb-lg-0">
          <h5 class="heading mb-3">Resources</h5>
          <ul class="list-unstyled">
            <li>
              <a href="{{ baseUrl }}/blog">Health Resources</a>
            </li>
            <li><a href="{{ baseUrl }}/covid-19">Covid 19</a></li>
           
          </ul>
        </div>
        <div class="col-12 col-md-6 col-lg-2 mb-5 mb-lg-0">
          <h5 class="heading mb-3">Company</h5>
          <ul class="list-unstyled">
            <li><a href="{{ baseUrl }}/about-us">About Us</a></li>
          
            <li><a href="{{ baseUrl }}/membership">Membership</a></li>
            <li>
              <a href="{{ baseUrl }}/contact-us">Contact Us</a>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-6 col-lg-2 mb-5 mb-lg-0 footerinfo">
          <div class="support fhead">
            <h5>Support</h5>
            <p>
              <a href="mailto:support@secondconsult.com"
                >support@secondconsult.com</a
              >
            </p>
            <p>
              <a href="mailto:info@secondconsult.com">info@secondconsult.com</a>
            </p>
          </div>
          <div class="social fhead mb-3">
            <a
              href="https://www.linkedin.com/company/second-consult-healthcare/?viewAsMember=true"
              target="_blank"
            >
              <svg
                height="24pt"
                viewBox="0 0 512 512"
                width="24pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm-74.390625 387h-62.347656v-187.574219h62.347656zm-31.171875-213.1875h-.40625c-20.921875 0-34.453125-14.402344-34.453125-32.402344 0-18.40625 13.945313-32.410156 35.273437-32.410156 21.328126 0 34.453126 14.003906 34.859376 32.410156 0 18-13.53125 32.402344-35.273438 32.402344zm255.984375 213.1875h-62.339844v-100.347656c0-25.21875-9.027343-42.417969-31.585937-42.417969-17.222656 0-27.480469 11.601563-31.988282 22.800781-1.648437 4.007813-2.050781 9.609375-2.050781 15.214844v104.75h-62.34375s.816407-169.976562 0-187.574219h62.34375v26.558594c8.285157-12.78125 23.109375-30.960937 56.1875-30.960937 41.019531 0 71.777344 26.808593 71.777344 84.421874zm0 0"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.facebook.com/SecondConsultHealthcare"
              target="_blank"
            >
              <svg
                id="Capa_1"
                enable-background="new 0 0 512 512"
                height="24pt"
                viewBox="0 0 512 512"
                width="24pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m512 256c0-141.4-114.6-256-256-256s-256 114.6-256 256 114.6 256 256 256c1.5 0 3 0 4.5-.1v-199.2h-55v-64.1h55v-47.2c0-54.7 33.4-84.5 82.2-84.5 23.4 0 43.5 1.7 49.3 2.5v57.2h-33.6c-26.5 0-31.7 12.6-31.7 31.1v40.8h63.5l-8.3 64.1h-55.2v189.5c107-30.7 185.3-129.2 185.3-246.1z"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/secondconsulthealthcare/"
              target="_blank"
            >
              <svg
                height="24pt"
                viewBox="0 0 512 512"
                width="24pt"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m305 256c0 27.0625-21.9375 49-49 49s-49-21.9375-49-49 21.9375-49 49-49 49 21.9375 49 49zm0 0"
                ></path>
                <path
                  d="m370.59375 169.304688c-2.355469-6.382813-6.113281-12.160157-10.996094-16.902344-4.742187-4.882813-10.515625-8.640625-16.902344-10.996094-5.179687-2.011719-12.960937-4.40625-27.292968-5.058594-15.503906-.707031-20.152344-.859375-59.402344-.859375-39.253906 0-43.902344.148438-59.402344.855469-14.332031.65625-22.117187 3.050781-27.292968 5.0625-6.386719 2.355469-12.164063 6.113281-16.902344 10.996094-4.882813 4.742187-8.640625 10.515625-11 16.902344-2.011719 5.179687-4.40625 12.964843-5.058594 27.296874-.707031 15.5-.859375 20.148438-.859375 59.402344 0 39.25.152344 43.898438.859375 59.402344.652344 14.332031 3.046875 22.113281 5.058594 27.292969 2.359375 6.386719 6.113281 12.160156 10.996094 16.902343 4.742187 4.882813 10.515624 8.640626 16.902343 10.996094 5.179688 2.015625 12.964844 4.410156 27.296875 5.0625 15.5.707032 20.144532.855469 59.398438.855469 39.257812 0 43.90625-.148437 59.402344-.855469 14.332031-.652344 22.117187-3.046875 27.296874-5.0625 12.820313-4.945312 22.953126-15.078125 27.898438-27.898437 2.011719-5.179688 4.40625-12.960938 5.0625-27.292969.707031-15.503906.855469-20.152344.855469-59.402344 0-39.253906-.148438-43.902344-.855469-59.402344-.652344-14.332031-3.046875-22.117187-5.0625-27.296874zm-114.59375 162.179687c-41.691406 0-75.488281-33.792969-75.488281-75.484375s33.796875-75.484375 75.488281-75.484375c41.6875 0 75.484375 33.792969 75.484375 75.484375s-33.796875 75.484375-75.484375 75.484375zm78.46875-136.3125c-9.742188 0-17.640625-7.898437-17.640625-17.640625s7.898437-17.640625 17.640625-17.640625 17.640625 7.898437 17.640625 17.640625c-.003906 9.742188-7.898437 17.640625-17.640625 17.640625zm0 0"
                ></path>
                <path
                  d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm146.113281 316.605469c-.710937 15.648437-3.199219 26.332031-6.832031 35.683593-7.636719 19.746094-23.246094 35.355469-42.992188 42.992188-9.347656 3.632812-20.035156 6.117188-35.679687 6.832031-15.675781.714844-20.683594.886719-60.605469.886719-39.925781 0-44.929687-.171875-60.609375-.886719-15.644531-.714843-26.332031-3.199219-35.679687-6.832031-9.8125-3.691406-18.695313-9.476562-26.039063-16.957031-7.476562-7.339844-13.261719-16.226563-16.953125-26.035157-3.632812-9.347656-6.121094-20.035156-6.832031-35.679687-.722656-15.679687-.890625-20.6875-.890625-60.609375s.167969-44.929688.886719-60.605469c.710937-15.648437 3.195312-26.332031 6.828125-35.683593 3.691406-9.808594 9.480468-18.695313 16.960937-26.035157 7.339844-7.480469 16.226563-13.265625 26.035157-16.957031 9.351562-3.632812 20.035156-6.117188 35.683593-6.832031 15.675781-.714844 20.683594-.886719 60.605469-.886719s44.929688.171875 60.605469.890625c15.648437.710937 26.332031 3.195313 35.683593 6.824219 9.808594 3.691406 18.695313 9.480468 26.039063 16.960937 7.476563 7.34375 13.265625 16.226563 16.953125 26.035157 3.636719 9.351562 6.121094 20.035156 6.835938 35.683593.714843 15.675781.882812 20.683594.882812 60.605469s-.167969 44.929688-.886719 60.605469zm0 0"
                ></path>
              </svg>
            </a>
          </div>
          <!-- <div class="social fhead mb-4">
            <a href="#"
              ><svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>linkedin (2)</title>
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="List-your-practise"
                    transform="translate(-1166.000000, -5750.000000)"
                    fill="#FFFFFF"
                    fill-rule="nonzero"
                  >
                    <g
                      id="Group-24"
                      transform="translate(0.000000, 5441.000000)"
                    >
                      <g
                        id="Group-11"
                        transform="translate(581.000000, 53.000000)"
                      >
                        <g
                          id="linkedin-(2)"
                          transform="translate(585.000000, 256.000000)"
                        >
                          <path
                            d="M12,0 C5.3735962,0 0,5.3735962 0,12 C0,18.6264038 5.3735962,24 12,24 C18.6264038,24 24,18.6264038 24,12 C24,5.3735962 18.6264038,0 12,0 Z M8.51293945,18.140625 L5.59039308,18.140625 L5.59039308,9.34808348 L8.51293945,9.34808348 L8.51293945,18.140625 Z M7.05175781,8.14746094 L7.03271484,8.14746094 C6.05200195,8.14746094 5.41772461,7.47235106 5.41772461,6.62860106 C5.41772461,5.76580809 6.07141116,5.109375 7.07116697,5.109375 C8.07092287,5.109375 8.68615725,5.76580809 8.70520022,6.62860106 C8.70520022,7.47235106 8.07092287,8.14746094 7.05175781,8.14746094 Z M19.0510254,18.140625 L16.1288452,18.140625 L16.1288452,13.4368286 C16.1288452,12.2546997 15.7056885,11.4484863 14.6482544,11.4484863 C13.8409424,11.4484863 13.3601074,11.9923096 13.1488037,12.5172729 C13.0715332,12.7051392 13.0526733,12.9677124 13.0526733,13.2304688 L13.0526733,18.140625 L10.13031,18.140625 C10.13031,18.140625 10.1685791,10.1729737 10.13031,9.34808348 L13.0526733,9.34808348 L13.0526733,10.5930176 C13.4410401,9.99389648 14.1359253,9.14172366 15.6864624,9.14172366 C17.6092529,9.14172366 19.0510254,10.3983765 19.0510254,13.098999 L19.0510254,18.140625 Z"
                            id="Shape"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
            <a href="#"
              ><svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>facebook (2)</title>
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="List-your-practise"
                    transform="translate(-1213.000000, -5750.000000)"
                    fill="#FFFFFF"
                    fill-rule="nonzero"
                  >
                    <g
                      id="Group-24"
                      transform="translate(0.000000, 5441.000000)"
                    >
                      <g
                        id="Group-11"
                        transform="translate(581.000000, 53.000000)"
                      >
                        <g
                          id="facebook-(2)"
                          transform="translate(632.000000, 256.000000)"
                        >
                          <path
                            d="M24,12 C24,5.371875 18.628125,0 12,0 C5.371875,0 0,5.371875 0,12 C0,18.628125 5.371875,24 12,24 C12.0703125,24 12.140625,24 12.2109375,23.9953125 L12.2109375,14.6578125 L9.6328125,14.6578125 L9.6328125,11.653125 L12.2109375,11.653125 L12.2109375,9.440625 C12.2109375,6.8765625 13.7765625,5.4796875 16.0640625,5.4796875 C17.1609375,5.4796875 18.103125,5.559375 18.375,5.596875 L18.375,8.278125 L16.8,8.278125 C15.5578125,8.278125 15.3140625,8.86875 15.3140625,9.7359375 L15.3140625,11.6484375 L18.290625,11.6484375 L17.9015625,14.653125 L15.3140625,14.653125 L15.3140625,23.5359375 C20.3296875,22.096875 24,17.4796875 24,12 Z"
                            id="Path"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
            <a href="#">
              <svg
                width="24px"
                height="20px"
                viewBox="0 0 24 20"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <title>twitter (2)</title>
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="List-your-practise"
                    transform="translate(-1268.000000, -5752.000000)"
                    fill="#FFFFFF"
                    fill-rule="nonzero"
                  >
                    <g
                      id="Group-24"
                      transform="translate(0.000000, 5441.000000)"
                    >
                      <g
                        id="Group-11"
                        transform="translate(581.000000, 53.000000)"
                      >
                        <g
                          id="twitter-(2)"
                          transform="translate(687.000000, 258.000000)"
                        >
                          <path
                            d="M24,2.36769231 C23.1075,2.76923077 22.1565,3.03538462 21.165,3.16461538 C22.185,2.54 22.9635,1.55846154 23.3295,0.375384615 C22.3785,0.956923077 21.3285,1.36769231 20.2095,1.59692308 C19.3065,0.610769231 18.0195,0 16.6155,0 C13.8915,0 11.6985,2.26769231 11.6985,5.04769231 C11.6985,5.44769231 11.7315,5.83230769 11.8125,6.19846154 C7.722,5.99384615 4.1025,3.98307692 1.671,0.92 C1.2465,1.67538462 0.9975,2.54 0.9975,3.47076923 C0.9975,5.21846154 1.875,6.76769231 3.183,7.66461538 C2.3925,7.64923077 1.617,7.41384615 0.96,7.04307692 C0.96,7.05846154 0.96,7.07846154 0.96,7.09846154 C0.96,9.55076923 2.6655,11.5876923 4.902,12.0569231 C4.5015,12.1692308 4.065,12.2230769 3.612,12.2230769 C3.297,12.2230769 2.979,12.2046154 2.6805,12.1369231 C3.318,14.1353846 5.127,15.6046154 7.278,15.6523077 C5.604,16.9953846 3.4785,17.8046154 1.1775,17.8046154 C0.774,17.8046154 0.387,17.7861538 0,17.7353846 C2.1795,19.1769231 4.7625,20 7.548,20 C16.602,20 21.552,12.3076923 21.552,5.64 C21.552,5.41692308 21.5445,5.20153846 21.534,4.98769231 C22.5105,4.27692308 23.331,3.38923077 24,2.36769231 Z"
                            id="Path"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </a>
          </div> -->
        </div>
      </div>
      <hr class="divider divider-fade divider-dark my-4" />
      <div class="row align-items-center justify-content-md-between pb-4">
        <div class="col-12 col-md-6">
          <div class="copyright anr text-center text-md-left">
            &#169; {{ copyright_information }}.
          </div>
        </div>
        <div class="col-12 col-md-6">
          <ul
            class="
              nav
              justify-content-center justify-content-md-end
              mt-3 mt-md-0
            "
          >
            <li class="nav-item">
              <a class="nav-link" href="{{ baseUrl }}/terms">Terms</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="{{ baseUrl }}/privacy-policy"
                >Privacy</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="{{ baseUrl }}/hipaa">HIPPAA</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" href="{{ baseUrl }}/data-privacy"
                >Data Privacy</a
              >
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- <footer>
  <div class="container">
    <div class="row padbot30">
      <div class="col col-12 col-lg-4 col-md-4 padtop35 padbot20">
        <figure>
          <a routerLink=""
            ><img
              src="/assets/images/footer-logo.png"
              class="img-fluid width68"
          /></a>
        </figure>
      </div>
      <div class="col col-6 col-lg-3 col-md-3 padtop35 padbot20">
        <ul class="footer-link">
          <li class="footer-nav"><a routerLink="/about-us">About us</a></li>
          <li class="footer-nav"><a routerLink="/contact-us">Contact us</a></li>
          <li class="footer-nav"><a routerLink="/faq">FAQ's</a></li>
        </ul>
      </div>
      <div class="col col-6 col-lg-3 col-md-3 padtop35 padbot20">
        <ul class="footer-link">
          <li class="footer-nav">
            <a routerLink="/privacy-policy">Privacy Policy</a>
          </li>
          <li class="footer-nav">
            <a routerLink="/terms-condition">Terms of Use</a>
          </li>
          <li class="footer-nav"><a routerLink="/blog">Blog</a></li>
        </ul>
      </div>
      <div class="col col-12 col-lg-2 col-md-2 padtop35 padbot20">
        <h4 class="social-titile">Follow Us</h4>
        <ul class="social footer-link">
          <li class="social-icons">
            <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
          </li>
          <li class="social-icons">
            <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
          </li>
          <li class="social-icons">
            <a href="#"
              ><i class="fa fa-linkedin-square" aria-hidden="true"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="footerrights text-center pad10s">
      <p class="footertext">&copy; 2019 SECONDCONSULT.ALL RIGHTS RESERVED.</p>
    </div>
  </div>
</footer> -->
