import { Injectable, TemplateRef } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ToastService {
  toasts: any[] = [];

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  showSuccess(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    options.classname = "bg-success";
    options.delay = options.delay || 3000;
    this.toasts.push({ textOrTpl, ...options });
  }

  showAlert(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    options.classname = "bg-danger color-white";
    options.delay = options.delay || 3000;
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
