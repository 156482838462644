export const TimezoneValues = [
  { id: "CST", name: "United States and Canada (CST)" },
  { id: "EST", name: "United States and Canada (EST)" },
  { id: "PST", name: "United States and Canada (PST)" },
  { id: "AST", name: "United States and Canada (AST)" }, // AST: UTC−04:00
  { id: "AKST", name: "United States and Canada (AKST)" }, //AKST: UTC−09:00
  { id: "MST", name: "United States and Canada (MST)" }, // MST: UTC−07:00
  { id: "AEST", name: "Australia (AEST)" },
  { id: "GMT+5:30", name: "India (IST)" },
  { id: "GMT+8:00", name: "Singapore (SGT)" },
  { id: "GMT+1:00", name: "United Kingdom (GMT)" },
  { id: "GMT+4:00", name: "Abu Dhabi (UAE)" },
];
export const APP_CONSTANTS = {
  site_name: "Secondconsult LLP",
};
