import { AuthGuard } from "./_guards/auth.guard";
import { Routes } from "@angular/router";
import { HeaderComponent } from "../app/layout/header/header.component";
import { LoginlayoutComponent } from "../app/layout/loginlayout/loginlayout.component";
import { NotFoundComponent } from "./not-found/not-found.component";

export const AppRoutes: Routes = [
  {
    path: "",
    component: LoginlayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./login/login.module").then((module) => module.LoginModule),
      },
      {
        path: "reset-password",
        loadChildren: () =>
          import("./reset-password/reset-password.module").then(
            (module) => module.ResetPasswordModule
          ),
      },
      {
        path: "registration/signup",
        loadChildren: () =>
          import("./registration/sign-up/sign-up.module").then(
            (module) => module.SignUpModule
          ),
      },
      {
        path: "registration/personal-info",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./registration/personal-info/personal-Info.module").then(
            (module) => module.PersonalInfoModule
          ),
      },
      {
        path: "registration/education",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./registration/education/education.module").then(
            (module) => module.EducationModule
          ),
      },
      {
        path: "registration/specialization",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./registration/specialization/specialization.module").then(
            (module) => module.SpecializationModule
          ),
      },
      {
        path: "registration/awards-membership",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./registration/awards/awards.module").then(
            (module) => module.AwardsModule
          ),
      },
    ],
  },
  {
    path: "",
    component: HeaderComponent,
    children: [
      {
        path: "dashboard",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./dashboard/dashboard.module").then(
            (module) => module.DashboardModule
          ),
      },
      {
        path: "appointments",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./appointment/appointments.module").then(
            (module) => module.AppointmentsModule
          ),
      },
      {
        path: "appointment-details",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./appointment-details/appointment-details.module").then(
            (module) => module.AppointmentDetailsModule
          ),
      },
      // {
      //   path: "old-appointment-details",
      //   canActivate: [AuthGuard],
      //   loadChildren: () =>
      //     import("./old-appointment-details/appointment-details.module").then(
      //       (module) => module.AppointmentDetailsModule
      //     ),
      // },
      {
        path: "second-opinion",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./second-opinion/second-opinion.module").then(
            (module) => module.SecondOpinionModule
          ),
      },
      {
        path: "second-opinion-details",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./second-opinion-details/second-opinion-detail.module").then(
            (module) => module.SecondOpinionDetailModule
          ),
      },
      {
        path: "schedule",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./schedule/schedule.module").then(
            (module) => module.ScheduleModule
          ),
      },
      {
        path: "availability",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./availability/availability.module").then(
            (module) => module.AvailabilityModule
          ),
      },
      {
        path: "my-patients",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./patients/patients.module").then(
            (module) => module.PatientsModule
          ),
      },
      {
        path: "patient-details",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./patient-details/patient-details.module").then(
            (module) => module.PatientDetailsModule
          ),
      },
      {
        path: "revenue",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./revenue/revenue.module").then(
            (module) => module.RevenueModule
          ),
      },
      {
        path: "settings",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./settings/settings.module").then(
            (module) => module.SettingsModule
          ),
      },
      {
        path: "profile",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./profile/profile.module").then(
            (module) => module.ProfileModule
          ),
      },
      {
        path: "transactions",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./transactions/transactions.module").then(
            (module) => module.TransactionsModule
          ),
      },
      {
        path: "payouts",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./payouts/payouts.module").then(
            (module) => module.PayoutsModule
          ),
      },
      {
        path: "help-videos",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./help-videos/help-videos.module").then(
            (module) => module.HelpVideosModule
          ),
      },
      {
        path: "doctor-schedule",
        canActivate: [AuthGuard],
        loadChildren: () =>
          import("./doctor-schedule/doctor-schedule.module").then(
            (module) => module.DoctorScheduleModule
          ),
      },
      {
        path: "app-redirection",
        loadChildren: () =>
          import("./app-redirection/app-redirection.module").then(
            (module) => module.AppRedirectionModule
          ),
      },
    ],
  },
  {
    path: "not-found",
    component: NotFoundComponent,
  },
];
