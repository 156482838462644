import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams} from "@angular/common/http";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class PersonalInfoService {
  constructor(private http: HttpClient) {}

  getProfile() {
    let apiURL = API_URL + "get-doctor-profile";
    return this.http.get(apiURL);
  }

  saveProfile(data) {
    let apiURL = API_URL + "save-doctor-profile";
    return this.http.post(apiURL, data);
  }
  updatePublicProfile(public_profile) {
    let data = { public_profile: public_profile };
    let apiURL = API_URL + "doctors/update-public-profile";
    return this.http.put(apiURL, data);
  }

  getTimeZone() {
    let apiUrl = `${API_URL}timezones`;
    return this.http.get(apiUrl);
  }

  deleteSchedule(data) {
    let params = new HttpParams();
    let doctor_id =  data.doctor_id;
    params = params.append("start_date", data.start_date);
    params = params.append("end_date", data.end_date);
    let apiURL = API_URL + "doctors/" + doctor_id + "/schedules?" + params;
    return this.http.delete(apiURL);
  }
}
