import { CommonService } from "src/app/common/common.service";
import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
// user.ts
export interface LoginResponse {
  access_token: string;
  data: any;
  name: string;
  status: string;
  message: string;
}

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(
    public jwtHelper: JwtHelperService,
    private cookieService: CookieService,
    private http: HttpClient,
    private route: Router,
    private api: CommonService,
    public snackBar: MatSnackBar
  ) {}

  getToken() {
    localStorage.setItem(
      "access_token",
      JSON.stringify(this.cookieService.get("doctor_access_token"))
    );
    return this.cookieService.get("doctor_access_token");
  }

  getUserData() {
    const token = this.getToken();
    if (token) {
      return this.jwtHelper.decodeToken(token);
    }

    this.logout();
  }

  isExpired() {
    const token = this.getToken();
    return this.jwtHelper.isTokenExpired(token);
  }

  getUserRole() {
    const userData = this.getUserData();
    return userData.role;
  }

  login(user: any): Observable<any> {
    let param = new HttpParams();
    param = param.append("email", user.email);
    param = param.append("password", user.pass);
    param = param.append("remember", user.remember);
    return this.http.post(API_URL + "login-doctor", param);
  }

  // After login save token and other values(if any) in localStorage
  setUser(response: LoginResponse, registraion = false) {
    var myDate = new Date();
    const url = environment.doctorUrl.replace(/(^\w+:|^)\/\//, "");
    myDate.setMonth(myDate.getMonth() + 12);
    localStorage.setItem("name", response.name || "");
    localStorage.setItem("access_token", response.access_token);
    document.cookie =
      "doctor_access_token=" +
      response.access_token +
      ";expires=" +
      myDate +
      `;domain=.${url};path=/`;
    // if (!registraion) {
    //   window.location.href = environment.doctorUrl + "/#/dashboard";
    // } else {
    this.navigateSelectedUser();
    // }
  }

  // Checking if token is set
  isLoggedIn() {    
    let token = this.cookieService.get("doctor_access_token");
    if (token && !this.isExpired()) {
      return true;
    } else {      
      const url = environment.doctorUrl.replace(/(^\w+:|^)\/\//, "");
      this.cookieService.delete("doctor_access_token", "/", "." + url);
      return false;
    }
    //return true if user logged in
  }

  // After clearing localStorage redirect to login screen
  logout() {
    const url = environment.doctorUrl.replace(/(^\w+:|^)\/\//, "");
    this.cookieService.delete("doctor_access_token", "/", "." + url);
    let loginurl = environment.mainUrl + "/#/login";
    window.location.href = loginurl;
  }
  navigateSelectedUser() {
    this.api.getProfile().subscribe(
      (response: any) => {
        let userInfo = response.data;
        userInfo.is_profilecompleted
          ? this.route.navigate(["/dashboard"])
          : this.route.navigateByUrl("/registration/personal-info");

        return;

        if (
          userInfo.is_profilecompleted &&
          userInfo.is_educationcompleted &&
          userInfo.is_specializationcompleted
        ) {
          this.route.navigate(["/dashboard"]);
        } else if (!userInfo.is_profilecompleted) {
          this.openSnackBar(
            "Before going to profile complete your personal information",
            ""
          );
          this.route.navigateByUrl("/registration/personal-info");
        } else if (!userInfo.is_educationcompleted) {
          this.openSnackBar(
            "Before going to profile complete your details",
            ""
          );
          this.route.navigate(["/registration/education"]);
        } else if (!userInfo.is_specializationcompleted) {
          this.openSnackBar(
            "Before going to profile complete your details",
            ""
          );
          this.route.navigate(["/registration/specialization"]);
        }
      },
      (err) => {}
    );
  }
  openSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = "center";
    config.verticalPosition = "top";
    config.panelClass = ["green-snackbar"];
    this.snackBar.open(message, action, config);
  }
}
