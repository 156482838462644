<div class="gl-alert" *ngIf="is_next_apt">
  <div>
    You have an appointment scheduled at
    {{ is_next_apt.appointment_time }} ({{ gettimezone(model.timezone) }}) on
    {{ is_next_apt.booking_date }} with
    {{ is_next_apt.patient_name | titlecase }}.<a
      class="deferred-link cursor-pointer"
      (click)="reloadPage()"
      >Click Here </a
    >to view the appointment.
  </div>
</div>
<nav
  class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
  id="sidebar"
>
  <div class="container-fluid">
    <!-- Toggler -->
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#sidebarCollapse"
      aria-controls="sidebarCollapse"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleCollapse()"
      [ngClass]="{ show: show }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
      >
        <path
          stroke="#fff"
          stroke-linecap="round"
          stroke-miterlimit="10"
          stroke-width="2"
          d="M4 7h22M4 15h22M4 23h22"
        />
      </svg>

      <svg
        version="1.1"
        id="Capa_1"
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512.001 512.001"
        style="enable-background: new 0 0 512.001 512.001"
        xml:space="preserve"
        class="close"
      >
        <path
          d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717    L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859    c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287    l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285    L284.286,256.002z"
        />
      </svg>
    </button>

    <!-- Brand -->
    <div class="profilepic">
      <a
        aria-haspopup="true"
        aria-expanded="false"
        class="avatar avatar-sm avatar-online"
      >
        <ng-template #notShowImage>
          <img
            class="avatar-img rounded-circle"
            src="assets/images/default_profilepic.png"
            alt="..."
          />
        </ng-template>
        <img
          id="make_pic"
          onerror="this.src='/assets/images/default_profilepic.png'"
          class="avatar-img rounded-circle"
          src="{{ model.profile_pic }}"
          *ngIf="model.profile_pic !== null; else notShowImage"
      /></a>
      <div class="patinfo">
        <h3>{{ model.name }}</h3>
        <div class="pid">{{ model.user_uniqueid }}</div>
        <div class="pid">{{ model.category }}</div>
        <a class="update" routerLink="/profile">View Profile</a>
      </div>
    </div>
    <!-- User (xs) -->
    <div class="mr-4 d-none d-md-flex"></div>
    <div class="navbar-user d-md-none">
      <!-- Dropdown -->
      <div class="dropdown">
        <!-- Toggle -->
        <a
          href="#"
          id="sidebarIcon"
          class="dropdown-toggle"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div class="avatar avatar-sm avatar-online">
            <img
              *ngIf="!model.profile_pic"
              class="avatar-img rounded-circle"
              src="/assets/images/avatar-1.png"
              alt="{{ model.first_name }} {{ model.last_name }}"
            />
            <img
              *ngIf="model.profile_pic"
              class="avatar-img rounded-circle"
              [src]="model.profile_pic"
              alt="{{ model.first_name }} {{ model.last_name }}"
              onerror="this.src='/assets/images/avatar-1.png'"
            />
          </div>
        </a>

        <!-- Menu -->
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="sidebarIcon"
        >
          <a href="doctor_profile.html" class="dropdown-item">Profile</a>
          <a href="doctor_settings" class="dropdown-item">Settings</a>
          <hr class="dropdown-divider" />
          <a (click)="logout()" class="dropdown-item cursor-pointer">Logout</a>
        </div>
      </div>
    </div>

    <!-- Collapse -->
    <div
      class="collapse navbar-collapse"
      id="sidebarCollapse"
      (click)="toggleCollapse()"
      [ngClass]="{ show: show }"
    >
      <!-- Form -->
      <!-- <form class="mt-4 mb-3 d-md-none">
        <div class="input-group input-group-rounded input-group-merge">
          <input
            type="search"
            class="form-control form-control-rounded form-control-prepended"
            placeholder="Search"
            aria-label="Search"
          />
          <div class="input-group-prepend">
            <div class="input-group-text">
              <span class="fe fe-search"></span>
            </div>
          </div>
        </div>
      </form> -->

      <!-- Navigation -->
      <app-sidebar></app-sidebar>
    </div>
    <!-- / .navbar-collapse -->
  </div>
</nav>

<div class="main-content">
  <nav
    class="navbar navbar-expand-md navbar-light d-none d-md-flex"
    id="topbar"
  >
    <div class="container-fluid">
      <div class="form-inline mr-4 d-none d-md-flex">
        <a _ngcontent-rvi-c105="" class="navbar-brand cursor-pointer">
          <img src="../../../assets/images/sc_logo-1.png" alt="..." class=""
        /></a>
      </div>
      <!-- Form -->
      <!--  <form class="form-inline mr-4 d-none d-md-flex">
        <div
          class="input-group input-group-flush input-group-merge"
          data-list='{"valueNames": ["name"]}'
        >
         
        <input
            type="search"
            class="form-control form-control-prepended dropdown-toggle list-search"
            data-toggle="dropdown"
            placeholder="Search"
            aria-label="Search"
          />

         
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fe fe-search"></i>
            </div>
          </div>
        </div>
      </form>-->

      <!-- User -->
      <div class="form-inline mr-4 d-none d-md-flex"></div>
      <!-- <div class="navbar-user">
       
        <div class="dropdown">
        
          <a
            href="#"
            class="avatar avatar-sm avatar-online dropdown-toggle"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              *ngIf="!model.profile_pic"
              class="avatar-img rounded-circle"
              src="/assets/images/avatar-1.png"
              alt="{{ model.first_name }} {{ model.last_name }}"
            />
            <img
              *ngIf="model.profile_pic"
              class="avatar-img rounded-circle"
              [src]="assetUrl + model.profile_pic"
              alt="{{ model.first_name }} {{ model.last_name }}"
              onerror="this.src='/assets/images/avatar-1.png'"
            />
          </a>

          
          <div class="dropdown-menu dropdown-menu-right">
            <a routerLink="/profile" class="dropdown-item">Profile</a>
            <a routerLink="/settings" class="dropdown-item">Settings</a>
            <hr class="dropdown-divider" />
            <a (click)="logout()" class="dropdown-item cursor-pointer"
              >Logout</a
            >
          </div>
        </div>
      </div> -->
    </div>
  </nav>
  <ng-container>
    <div
      class="alert payment-alert"
      role="alert"
      *ngIf="!model.is_bank_details_updated || is_profileCompleted"
    >
      <span *ngIf="!model.is_bank_details_updated">
        Please add your bank details</span
      >
      <span *ngIf="!model.is_bank_details_updated && is_profileCompleted">
        and
      </span>
      <span *ngIf="is_profileCompleted">complete your profile </span>
    </div>
  </ng-container>
  <router-outlet></router-outlet>
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>
