import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { FooterService } from "./footer.service";
import { APP_CONSTANTS } from "../../common/config.component";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  mainUrl: any;
  baseUrl: any;
  specialtyList: any;
  currentYear;
  copyright_information: any;

  constructor(private api: FooterService) {}

  ngOnInit() {
    this.mainUrl = environment.mainUrl;
    this.baseUrl = environment.baseUrl;
    this.api.getSpecialties().subscribe((jsonData) => {
      this.specialtyList = jsonData.data;
    });
    let currentYear = new Date().getFullYear();
    let site_name = APP_CONSTANTS.site_name;
    this.copyright_information = currentYear + " " + site_name;
  }
  signIn() {
    window.location.href = this.mainUrl + "/#login";
  }
  signUp() {
    window.location.href = this.mainUrl + "/#/registration/patient-information";
  }
}
