import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";

const API_URL = environment.apiUrl;

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) {}

  sentForgetPwdEmail(user: any): Observable<any> {
    let param = new HttpParams();
    param = param.append("email", user.fgtmail);
    param = param.append("user_type", "doctor");
    return this.http.post(API_URL + "sent-forgetpwd-email", param);
  }

  resetPassSet(user: any, code: any): Observable<any> {
    let param = new HttpParams();
    param = param.append("new_password", user.password);
    param = param.append("conf_password", user.cpassword);
    param = param.append("user_type", "doctor");
    return this.http.post(API_URL + "reset-password/" + code, param);
  }

  validateResetPass(code: any): Observable<any> {
    return this.http.get(API_URL + "validate-rplink/" + code);
  }
}
