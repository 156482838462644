import { MatSnackBarModule } from "@angular/material";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtModule } from "@auth0/angular-jwt";
import { SCInterceptor } from "./_interceptor/sc.interceptor";
import { CookieService } from "ngx-cookie-service";

import { AppComponent } from "./app.component";
import { ApiService } from "./api.service";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { LoginlayoutComponent } from "./layout/loginlayout/loginlayout.component";
import { LoginHeaderComponent } from "./layout/loginheader/loginheader.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastsContainer } from "./common/toast.component";

import { environment } from "src/environments/environment";
import { NgxAgoraModule } from "ngx-agora";

// Routing Module
import { AppRoutes } from "./app.routing";
import { ToastrModule } from "ngx-toastr";
import { ChatComponentToast } from "../app/components/chat-component/chat-component.toast"

export function jwtgetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
    }),
    JwtModule.forRoot({
      config: {
        whitelistedDomains: [],
        blacklistedRoutes: [],
        tokenGetter: jwtgetter,
      },
    }),
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
    ToastrModule.forRoot({
      toastComponent: ChatComponentToast,
      timeOut: 2000,
      progressBar: true,
      progressAnimation: "increasing",
      preventDuplicates: true,
      tapToDismiss: false,
    }),
  ],
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    ToastsContainer,
    FooterComponent,
    LoginlayoutComponent,
    LoginHeaderComponent,
    ChatComponentToast,
  ],
  entryComponents: [ChatComponentToast],
  providers: [
    ApiService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SCInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule],
})
export class AppModule {}
